/**
Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021
**/
/**
File Name: pondmother.js
Description: This file contains all the vuex store functions used in PondMother page
*/
/* eslint-disable */
import PondMother from "../../model/pondmother";
import { cloneDeep, findIndex } from "lodash";
import bkPermissions from "@/constants/bkPermissions";
import PondMotherService from "../../services/PondMotherService";
import i18n from "@/config/i18n";
const state = {
  pondMothers: [],
  mapPmidPm: null
};
const getters = {
  getPondMothers: state => {
    return state.pondMothers.map(pm => {
      const managedByToLang = {
        SHRIMP_TALK: "PM_automatic",
        FARMER: "Comn_schedule",
        MANUAL: "PM_sch_mode4",
        NO_PMS: "-"
      };
      const eachPm = cloneDeep(pm);
      eachPm.allowedModes = [pm.mode];
      if (eachPm.mode === "HYBRID") {
        eachPm.allowedModes = ["SCHEDULE"];
        if (pm.shrimp_talk_id) {
          eachPm.allowedModes.push("AUTOMATIC");
        }
      }
      eachPm.mode_lang = i18n.availableLocales.map(locale => {
        return i18n.tc(managedByToLang[pm.managed_by], 1, locale);
      });
      return eachPm;
    });
  },
  getObjPmIdPm: (state, getters) => {
    let objPmIdPm = {};
    getters.getPondMothers.forEach(pm => {
      objPmIdPm[pm._id] = pm;
    });
    return objPmIdPm;
  },
  getPmIdPm: state => state.mapPmidPm,
  getMapPmIdMode: state => {
    let mapPmIdMode = {};
    let pms = state.pondMothers;
    for (let pm = 0; pm < pms.length; pm++) {
      const pmObj = pms[pm];
      mapPmIdMode[pmObj._id] = pmObj.mode;
    }
    return mapPmIdMode;
  },
  getPmsInAutomaticMode: (state, getters) => {
    return getters["getPondMothers"].filter(pm => {
      return pm.managed_by === "SHRIMP_TALK";
    });
  },
  getPmsInAutomaticModeGroupByPond: (state, getters) => {
    const pondGPmAuto = {};
    getters["getPmsInAutomaticMode"].forEach(pm => {
      if (typeof pondGPmAuto[pm.pond_id] === "undefined") {
        pondGPmAuto[pm.pond_id] = [];
      }
      pondGPmAuto[pm.pond_id].push(pm);
    });
    return pondGPmAuto;
  },
  getPmsInBasicMode: (state, getters) => {
    return getters["getPondMothers"].filter(pm => {
      return pm.managed_by === "MANUAL";
    });
  },
  getPmsInScheduleMode: (state, getters) => {
    return pms => {
      return pms.filter(pm => {
        return pm.managed_by === "FARMER";
      });
    };
  }
};
const mutations = {
  SET_POND_MOTHER_DATA(state, pondMotherData) {
    if (pondMotherData != null) {
      let pmArr = [];
      pondMotherData.forEach(pondmother => {
        let pmObj = new PondMother();
        pmObj.castToPMObj(pondmother);
        pmArr.push(pmObj);
      });
      state.pondMothers = pmArr;
    } else {
      state.pondMothers = null;
    }
  },
  SET_MAP_PMID_PM(state) {
    let mapPmidPm = new Map();
    if (state.pondMothers != null) {
      state.pondMothers.forEach(pondmother => {
        mapPmidPm.set(pondmother.pond_mother_id, pondmother);
      });
      state.mapPmidPm = mapPmidPm;
    } else {
      state.mapPmidPm = null;
    }
  },
  PUSH_UPDATED_POND(state, pmData) {
    let pmObj = new Pond();
    pmObj.castToPondObj(pmData);
    let index = findIndex(state.pondMothers, function(pondmother) {
      return pondmother.pond_mother_id === pmData.pond_mother_id;
    });
    state.pondMothers[index] = pmObj;
    if (state.mapPmidPm.get(pmObj.pond_mother_id)) {
      state.mapPmidPm.set(pmObj.pond_mother_id, pmObj);
    }
  }
};
const actions = {
  fetchAllPondMothers: function(context, queryParams) {
    return new Promise((resolve, reject) => {
      PondMotherService.fetchAllPondMothers(queryParams)
        .then(response => {
          context.commit("SET_POND_MOTHER_DATA", response.data.pond_mothers);
          context.commit("SET_MAP_PMID_PM");
          resolve({
            message: response.data.message,
            totalPMs: response.data.total,
            page: response.data.page
          });
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchAPondMother: function(context, pondMotherId) {
    return new Promise((resolve, reject) => {
      PondMotherService.fetchAPondMother(pondMotherId)
        .then(response => {
          //context.commit('SET_POND_MOTHER_DATA',response.data.pondMothers)
          resolve(response.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchPondMothersOnPondId: async function(context, pondId) {
    const requiredPermissions = bkPermissions["/ponds/:pond_id/pond-mothers"];
    const canAccessApi = context.rootGetters[
      "user/canUserAccessApiByBkPermissions"
    ](requiredPermissions);
    if (!canAccessApi) {
      console.error("No Permissions to call this api");
      return [];
    }
    try {
      const response = await PondMotherService.fetchPondMothersOnPondId(pondId);

      return response.data.pond_mothers;
    } catch (err) {
      throw err;
      console.error(err);
    }
  },
  addPondMotherDetails: function(context, pondMothers) {
    return new Promise((resolve, reject) => {
      PondMotherService.addPondMotherDetails(pondMothers)

        .then(response => {
          //  context.commit('SET_POND_MOTHER_DATA',response.data.pondMothers)
          resolve(response.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  updatePMSettings: function(context, pondMothers) {
    return new Promise((resolve, reject) => {
      PondMotherService.updatePMSettings(pondMothers)
        .then(response => {
          //context.commit('SET_POND_MOTHER_DATA',response.data.pondMothers)
          resolve(response.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  unAssignPondMothers: function(context, pondMother) {
    return new Promise((resolve, reject) => {
      PondMotherService.unAssignPondMothers(pondMother)
        .then(response => {
          resolve(response.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchSlotWisePMFeed: function(context, { pondmother, params }) {
    return new Promise((resolve, reject) => {
      PondMotherService.unAssignPondMothers(pondMother, params)
        .then(response => {
          resolve(response.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
