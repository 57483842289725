<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: skrettingTechnicianHeader.vue
Description: This file contains the header component which is the part of layout used in account manager site
-->
<template>
  <div
    class="account-header"
    style="display: flex; flex-direction: row; justify-content: space-between"
  >
    <div style="display: flex; flex-direction: row">
      <div class="left-container" style="">
        <pondlogs-logo class="menu-icon" color="#0A2463"></pondlogs-logo>
        &nbsp; &nbsp;
        <span class="nav__header-title">{{ $t("PondLogs") }}</span>
      </div>
    </div>
    <div style="display: flex; flex-direction: row; align-items: center">
      <div style="padding-right: 20px">
        <er-dropdown
          @item-click="handleChangeInLanguage"
          trigger="click"
          :listOfItems="langs"
        >
          <template slot="prefix" style="display: flex">
            <i class="el-icon-lang">
              <img
                src="../../assets/home/lang-icon.svg"
                height="15"
                width="20,"
              />
            </i>
          </template>
          <template slot="title">
            <p>{{ langSelected }}</p>
          </template>
        </er-dropdown>
      </div>

      <div class="right-header-container">
        <span class="header-right-user">
          {{
            getskrettingTechnicianName.first_name
              ? getskrettingTechnicianName.first_name +
                "\xa0\xa0" +
                getskrettingTechnicianName.last_name
              : ""
          }}
        </span>

        <el-divider
          class="user-logout-divider"
          direction="vertical"
        ></el-divider>
        <er-button class="logout-button" @click="logout" type="text">
          <el-row type="flex" :gutter="5">
            <el-col>{{ $t("skrettingTechnician.Comn_logout") }}</el-col>
            <el-col>
              <i class="el-icon-logout">
                <img class="logout-icon" src="@/assets/logout.svg" />
              </i>
            </el-col>
          </el-row>
        </er-button>
      </div>
    </div>
  </div>
</template>

<script>
import pondlogsLogo from "@/components/index/pondlogsLogo";
import { mapActions } from "vuex";
export default {
  components: {
    pondlogsLogo
  },
  data: function() {
    return {
      langs: { Español: "es", English: "en", Chinese: "zh", Portuguese: "pt", Vietnamese: "vi" },
      revLangs: { es: "Español", en: "English", zh: "Chinese", pt: "Portuguese", vi: "Vietnamese" }
    };
  },
  computed: {
    getskrettingTechnicianName() {
      return this.$store.getters[
        "skrettingTechnician/getSkrettingTechnicianProfile"
      ];
    },
    langSelected() {
      return this.revLangs[this.$i18n.locale];
    },
    activeRoute() {
      return this.$route.path.split("/")[2];
    }
  },
  methods: {
    ...mapActions("skrettingTechnician", {
      skrettingTechnicianNotifyBackendOnLogout:
        "skrettingTechnicianNotifyBackendOnLogout"
    }),
    handleChangeInLanguage(value) {
      console.log(value);
      this.$i18n.locale = value;
      const indexPath = this.$route.meta.langKey;
      const appTitle = document.getElementById("appTitle");
      appTitle.innerHTML = `${this.$t(indexPath)} | ${this.$t("PondLogs")}`;
      localStorage.setItem(`lang`, value);
    },

    async logout() {
      await this.$store.dispatch("auth/skrettingTechnicianSignOut");
      try {
        await this.skrettingTechnicianNotifyBackendOnLogout();
      } catch (err) {
        console.log("error notifying on skretting technician logout");
      }
      this.$router.push("/");
    },
    async initComponent() {
      try {
        this.loading = true;
        // await this.$store.dispatch("skrettingTechnician/fetchUserProfile");
      } catch (err) {
        console.log(err);
        // this.ehm__errorMessages(err, true);
      } finally {
        this.loading = false;
      }
    }
  },
  async mounted() {
    await this.initComponent();
  }
};
</script>

<style lang="scss" scoped>
.left-container {
  display: flex;
  align-items: center;
  margin-right: 20px;
  @media screen and (max-width: 1367px) {
    margin-top: -6px;
  }
}
.menu-icon {
  margin-top: 9px;
}
.right-header-container {
  margin-top: -10px;
}
.account-header {
  margin-top: 4px;
  .nav__header-title {
    @media screen and (max-width: 1367px) {
      margin-top: 5px;
    }
    @include responsiveProperty(
      font-size,
      $app_font_size_3,
      $app_font_size_xl,
      $app_font_size_xl
    );
    color: #1f2041;
    text-decoration: none;
    font-weight: 500;
  }
  .dealer-menu {
    z-index: 1000;
    &.el-menu--horizontal > .el-menu-item {
      height: 44px !important;
      line-height: 44px !important;
    }
  }
  .header-right-user {
    max-width: 200px;
    color: #606266;
    text-transform: capitalize;
    text-decoration: none;
    @include responsiveProperty(
      font-size,
      $app_font_size_1,
      $app_font_size_2,
      $app_font_size_3
    );
  }
  .er-dropdown {
    color: #606266;
  }
  .er-dropdown .er-dropdown-trigger__suffix .er-icon-caret {
    color: #606266;
  }

  .er-dropdown .er-dropdown-trigger__suffix {
    margin-left: 10px;
    margin-top: auto;
  }
}
</style>
