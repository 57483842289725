<template>
  <span :title="getScheduleMode(mode)">
    <svg
      class="mode-color"
      viewBox="0 0 8 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="4" cy="4" r="3" :fill="modeColor(mode)">
      </circle>
    </svg>
    <slot></slot>
  </span>
</template>

<script>
import PondMother from "@/model/pondmother";
  export default {
    name: "modeColor",
    props: {
      mode: {
        type: String,
        required: true,
      }
    },
    methods: {
      modeColor(mode) {
        return PondMother.generateColorForMode(mode).color;
      },
      getScheduleMode(mode) {
      const translations = {
        AUTOMATIC: this.$t("PM_automatic"),
        SCHEDULE: this.$t("Comn_schedule"),
        HYBRID: this.$t("hybrid"),
        BASIC: this.$t("PM_sch_mode4")
      };
      return translations[mode];
    }
    }
  }
</script>

<style scoped>
  .mode-color {
    height: 12px;
    width: 12px;
    padding-right: 4px;
  }
</style>
