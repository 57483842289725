/**
Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021
**/
/**
File Name: feedtemplate.js
Description: This file has the model,functions  of feed template object used in the pondlogs customer site
*/
import TimeSlot from "./timeslot";
import commonUtils, { castSecsHHmmStr } from "../utils/commonUtils";
import { MAX_E_TIME_SECS_ALLOWED } from "../constants/schedule";
import fieldLengths from "../constants/fieldLengths";
class FeedTemplate {
  constructor() {
    this._id = "";
    this.ft_id = "";
    this.location_id = "";
    this.hasPonds = false;
    this.ponds = [];
    this.status = "ACTIVE";
    this.timeSlots = [];
    this.title = "";
    this.type = "NORMAL";
    this.schedules = [];
    this.obj_locations = {};
  }

  static getSampleFT(locationId) {
    const ft = new FeedTemplate();
    const feed_percentage = [10, 8, 8, 15, 17, 18, 14, 10];
    const start_time = [
      "00:15",
      "07:00",
      "10:00",
      "14:00",
      "16:05",
      "18:05",
      "20:05",
      "21:35"
    ];
    const end_time = [
      "06:00",
      "09:00",
      "13:00",
      "16:00",
      "18:00",
      "20:00",
      "21:30",
      "23:30"
    ];
    for (let i = 0; i < 8; i++) {
      const ts = new TimeSlot();
      ts.feed_percentage = feed_percentage[i];
      ts.s_time = start_time[i];
      ts.e_time = end_time[i];
      ts.s_time_secs = commonUtils.timeStrHHmmVal(ts.s_time);
      ts.e_time_secs = commonUtils.timeStrHHmmVal(ts.e_time);
      ft.timeSlots.push(ts);
    }
    ft.location_id = locationId;
    return ft;
  }

  static getHybridSampleFT(locationId) {
    const ft = new FeedTemplate();
    const feed_percentage = [10, 8, 8, 15, 17, 18, 14, 10];
    ft.type = "HYBRID";
    const start_time = [
      "00:15",
      "07:00",
      "10:00",
      "14:00",
      "16:05",
      "18:05",
      "20:05",
      "21:35"
    ];
    const end_time = [
      "06:00",
      "09:00",
      "13:00",
      "16:00",
      "18:00",
      "20:00",
      "21:30",
      "23:30"
    ];
    for (let i = 0; i < 8; i++) {
      const ts = new TimeSlot();
      ts.feed_percentage = feed_percentage[i];
      ts.s_time = start_time[i];
      ts.e_time = end_time[i];
      ts.s_time_secs = commonUtils.timeStrHHmmVal(ts.s_time);
      ts.e_time_secs = commonUtils.timeStrHHmmVal(ts.e_time);
      ft.timeSlots.push(ts);
    }
    ft.location_id = locationId;
    return ft;
  }

  static getErrorFieldsObj() {
    return {
      title: "",
      location: "",
      status: "",
      ponds: null,
      timeSlots: null
    };
  }

  getPayloadFormat() {
    const timeSlotsGreaterThanFeedPerZero = this.timeSlots.filter(x => x.feed_percentage > 0)
    const tsModes = TimeSlot.getModeFromListTS(timeSlotsGreaterThanFeedPerZero);
    let type = "HYBRID";
    if (tsModes.length === 1 && tsModes[0] === "SCHEDULE") {
      type = "NORMAL";
    }
    return {
      _id: this._id,
      name: this.title,
      type: type,
      location_id: this.location_id,
      status: "ACTIVE",
      ponds: this.ponds,
      time_slots: timeSlotsGreaterThanFeedPerZero.map(timeslot => {
          return timeslot.getFeedTemplatePayloadObj();
        })
    };
  }

  validate() {
    const arrErrors = [];

    if (this.title === "") {
      arrErrors.push({
        type: "validation",
        validation_type: "field_required",
        message: "pls_enter_title_to_set",
        field_name: "Comn_alias_name"
      });
    } else if (this.title.length < fieldLengths.min_template_alias_name_length) {
      arrErrors.push({
        type: "validation",
        validation_type: "not_equal_to_threshold",
        message: "Comn_name_length_must_be_at_least_3_to_30_characters_long",
        field_name: "Comn_alias_name"
      });
    }
    const totalFeedRatio = this.timeSlots.reduce(
      (acc, x) => acc + x.feed_percentage,
      0
    );
    if (totalFeedRatio !== 100) {
      arrErrors.push({
        type: "validation",
        validation_type: "not_equal_to_threshold",
        message: "total_should_sum_to_threshold",
        threshold: 100,
        field_name: "feed_ratio_%"
      });
    }
    const timeSlotsToBeValidated = this.timeSlots.filter(
      x => x.feed_percentage
    );
    for (let i = 0; i < timeSlotsToBeValidated.length; i++) {
      const currTS = timeSlotsToBeValidated[i];
      if (!currTS.isValidSTime()) {
        arrErrors.push({
          type: "time",
          validation_type: "start_time_minimum",
          message: "PM_start_time_not_less_than_0015",
          position: i + 1
        });
      }
      if (!currTS.isValidETime()) {
        arrErrors.push({
          type: "time",
          validation_type: "end_time_maximum",
          message: "PM_end_time_less_than_end_time",
          end_time: castSecsHHmmStr(MAX_E_TIME_SECS_ALLOWED),
          position: i + 1
        });
      }
      if (!currTS.isETimeGrtrSTime()) {
        arrErrors.push({
          type: "time",
          validation_type: "end_time_greater_start_time",
          message: "PM_end_time_not_less_start_time",
          position: i + 1
        });
      }
      const result = this.isOverlap(currTS);
      console.log(result, this.feedTemplate);
      if (result[0] !== "NO_OVERLAP") {
        const overLapIndex =
          this.timeSlots.map(x => x.ui_id).indexOf(result[0]) + 1;
        arrErrors.push({
          type: "time",
          validation_type: "overlap",
          message: "PM_sch_is_overlapping_msg",
          position1: i + 1,
          position2: overLapIndex
        });
      }
    }
    return arrErrors;
  }

  isOverlap(currTS) {
    console.log(currTS);
    const allIntvlsSize = this.timeSlots.length;
    const temp = [...this.timeSlots];
    // const temp = [...allIntvls];
    // temp.sort((a, b) => a.e_time_secs - b.e_time_secs);
    for (let i = 0; i < allIntvlsSize; i++) {
      const prevTIntvl = temp[i];
      if (prevTIntvl.ui_id === currTS.ui_id) continue;
      const errMsgs = [];
      [[prevTIntvl, currTS]].forEach((tsIntvlPair, index) => {
        if (tsIntvlPair[0] && tsIntvlPair[1]) {
          const s1h1 = tsIntvlPair[0].s_time_secs;
          const s1h2 = tsIntvlPair[0].e_time_secs;
          // const s2h1 = tsIntvlPair[1].s_time_secs;
          const s2h2 = tsIntvlPair[1].e_time_secs;
          const schedIndex = tsIntvlPair[0].ui_id;
          // set 1 start time is smaller than set 2 start time
          // set 2 start time is smaller than set 1 end time
          // if (s1h1 <= s2h1 && s2h1 <= s1h2) {
          //   errMsgs.push(schedIndex);
          //   return;
          // }
          // set 1 start time is smaller than set 2 end time
          // set 2 end time is smaller than set 1 end time
          if (s1h1 <= s2h2 && s2h2 <= s1h2) {
            errMsgs.push(schedIndex);
          }
          // set 2 start time is smaller than set 1 start time
          // set 2 end time is smaller than set 1 end time
          // if (s2h1 <= s1h1 && s2h2 >= s1h2) {
          //   errMsgs.push(schedIndex);
          // }
        }
      });
      const errMsgsLen = errMsgs.length;
      if (errMsgsLen > 0) {
        return errMsgs;
      }
    }
    // not overlapping
    return ["NO_OVERLAP"];
  }

  static castToFeedTemplateObj(response) {
    const feedTemplate = new FeedTemplate();
    feedTemplate._id = response._id;
    feedTemplate.ft_id = feedTemplate._id;
    feedTemplate.title = response.name;
    feedTemplate.location_id = response.location_id;
    if (response.type) {
      feedTemplate.type = response.type;
    }
    if (response.ponds.length > 0) {
      feedTemplate.hasPonds = true;
      feedTemplate.ponds = response.ponds;
      // response.ponds.map(rPond => {
      //   const pond = new Pond();
      //   pond.castToPondObj(rPond);
      //   return pond;
      // });
    }
    feedTemplate.timeSlots = response.time_slots.map(timeslot => {
      return TimeSlot.getTimeSlotObjFromResponse(timeslot);
    });
    return feedTemplate;
  }
}

export default FeedTemplate;
