/**
Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021
**/
/**
File Name: schedule.js
Description: This file contains the constants used in the schedule pages.
*/
export const INIT_TS_OFFSET_FROM_CURR_TIME_IN_MINS = 10; // 10 minutes
export const OFFSET_BTW_TWO_TS_IN_MINS = 30; // 30 mins
export const OFFSET_FOR_LAST_COMMUNICATION_IN_MINS = 30;
export const INIT_OCF_VALUE = 200;
export const MAX_OCF_VALUE = 2500;
export const MIN_FEED_GAP_VALUE = 1;
export const MAX_FEED_GAP_VALUE = 1423;
export const MIN_TOTAL_FEED_VALUE_ST_MODE = 1;
export const MAX_TOTAL_FEED_VALUE_ST_MODE = 400; // changed from 250 to 400
export const TIME_TO_SAVE_DATA_IN_DEVICE_IN_SECS = 300;
export const MIN_S_TIME_SECS = 900;
export const MIN_E_TIME_SECS = 960;
export const MAX_E_TIME_SECS_ALLOWED = 86280;
export const MAX_AUTO_S_TIME_SECS = 0;
export const MAX_AUTO_E_TIME_SECS = 85800;
export const MAX_E_TIME_SECS = 86340;
export const MIN_ON_TIME_SECS = 4;
export const FUTURE_SCHEDULES_DAY_LIMIT = 14;
export const PM_TS_STATUS = {
  TO_BE_RUN: "TO_BE_RUN",
  UI_RUNNING: "UI_RUNNING",
  RUNNING: "RUNNING",
  PAUSED: "PAUSED",
  DELETED: "DELETED",
  COMPLETED: "COMPLETED",
  UI_COMPLETED: "UI_COMPLETED",
  ERRORED: "ERRORED",
  STOPPED: "STOPPED",
  INVALID: "INVALID"
};
export const dateQueryTypes = {
  TODAY: "TODAY",
  PAST: "PAST",
  FUTURE: "FUTURE"
};
export const POND_TS_STATUS = {
  TO_BE_RUN: "TO_BE_RUN",
  PM_DIFF_STATUS: "PM_DIFF_STATUS",
  UI_RUNNING: "UI_RUNNING",
  RUNNING: "RUNNING",
  PAUSED: "PAUSED",
  DELETED: "DELETED",
  COMPLETED: "COMPLETED",
  UI_COMPLETED: "UI_COMPLETED",
  ERRORED: "ERRORED",
  STOPPED: "STOPPED",
  INVALID: "INVALID"
};
export const POND_MODES = {
  HYBRID: "HYBRID",
  AUTOMATIC: "SHRIMP_TALK",
  BASIC: "MANUAL",
  SCHEDULE: "FARMER",
  MIXED: "MIXED"
};
export const PM_MODES = {
  AUTOMATIC: "SHRIMP_TALK",
  BASIC: "MANUAL",
  SCHEDULE: "FARMER"
};
export const TS_PM_MODES = {
  AUTOMATIC: "SHRIMP_TALK",
  BASIC: "MANUAL",
  SCHEDULE: "FARMER",
  SHRIMP_TALK: "SHRIMP_TALK",
  MANUAL: "MANUAL",
  FARMER: "FARMER",
  HYBRID: "HYBRID"
};
export const TS_BACKEND_KEYS = [
  "s_time",
  "s_time_secs",
  "e_time",
  "e_time_secs",
  "feed",
  "dispensed_feed",
  "feed_gap",
  "ocf",
  "status",
  "remaining_feed"
];
export const TS_CREATED_BY = {
  POND: "POND",
  POND_MOTHER: "POND_MOTHER",
  IT_SELF: "IT_SELF"
};
export const SAVED_AT_DEVICE_STATUS = {
  PARTIAL: "PARTIAL",
  COMPLETED: "COMPLETED",
  NOT_SAVED: "NOT_SAVED"
};
export const ALLOWED_PM_MODES_POND_FEEDING = [
  "SCHEDULE",
  "HYBRID.SCHEDULE",
  "HYBRID.AUTOMATIC"
];
export default {
  INIT_TS_OFFSET_FROM_CURR_TIME_IN_MINS,
  OFFSET_BTW_TWO_TS_IN_MINS,
  TIME_TO_SAVE_DATA_IN_DEVICE_IN_SECS,
  INIT_OCF_VALUE,
  MAX_FEED_GAP_VALUE,
  MAX_OCF_VALUE,
  MIN_FEED_GAP_VALUE,
  MIN_TOTAL_FEED_VALUE_ST_MODE,
  MAX_TOTAL_FEED_VALUE_ST_MODE,
  PM_TS_STATUS,
  POND_TS_STATUS,
  POND_MODES,
  PM_MODES,
  dateQueryTypes,
  MIN_S_TIME_SECS,
  MIN_E_TIME_SECS,
  MAX_E_TIME_SECS,
  MAX_E_TIME_SECS_ALLOWED,
  MAX_AUTO_E_TIME_SECS,
  MIN_ON_TIME_SECS,
  TS_BACKEND_KEYS,
  TS_CREATED_BY,
  SAVED_AT_DEVICE_STATUS,
  MAX_AUTO_S_TIME_SECS,
  FUTURE_SCHEDULES_DAY_LIMIT,
  ALLOWED_PM_MODES_POND_FEEDING
};
