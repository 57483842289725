<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: accountManagerHeader.vue
Description: This file contains the header component which is the part of layout used in account manager site
-->
<template>
  <div
    class="account-header"
    style="display: flex; flex-direction: row; justify-content: space-between"
  >
    <div style="display: flex; flex-direction: row">
      <div style="display: flex; align-items: center; margin-right: 20px">
        <pondlogs-logo class="menu-icon" color="#0A2463"></pondlogs-logo>
        &nbsp; &nbsp;
        <span class="nav__header-title">{{ $t("PondLogs") }}</span>
      </div>
    </div>
    <div style="
      display: flex;
      flex-direction: row;">
      <div>
        <el-menu
          class="dealer-menu"
          mode="horizontal"
          :default-active="activeRoute"
          :router="true"
        >
          <er-menu-item
            index="users"
            route="/account-manager/users"
            :isActive="activeRoute === 'users'"
            >{{ $t("DL_customers") }}</er-menu-item
          >
        <er-menu-item
          index="subusers"
          route="/account-manager/sub-users"
          :isActive="activeRoute === 'sub-users'"
          >{{ $t("skretting-technician") }}</er-menu-item
        >
        <er-menu-item
          v-if="showSubscription"
          index="subscriptions"
          route="/account-manager/subscriptions/year-wise"
          :isActive="activeRoute === 'subscriptions'"
          >{{ $t("Subscriptions") }}</er-menu-item
        >
        <el-submenu index="2">
          <template slot="title">
            <span class="devices">
              {{
                `${$t("Comn_devices")}  ${getSubMenuTitle($route.meta.langKey)}`
              }}</span
            >
          </template>
          <er-menu-item
            v-for="(item, index) in devicesList"
            :key="index"
            :index="item.index"
            :label="item.label"
            :route="item.route"
            :isActive="activeRoute === item.index"
          >
          </er-menu-item>
        </el-submenu>
      </el-menu>
      </div>
    </div>
    <div
      style="
        display: flex;
        flex-direction: row;
        align-items: center;
        z-index: 300;
      "
    >
      <div style="padding-right: 20px">
        <er-dropdown
          @item-click="handleChangeInLanguage"
          trigger="click"
          :listOfItems="langs"
        >
          <template slot="prefix" style="display: flex">
            <i class="el-icon-lang">
              <img
                src="../../assets/home/lang-icon.svg"
                height="15"
                width="20,"
              />
            </i>
          </template>
          <template slot="title">
            <p>{{ langSelected }}</p>
          </template>
        </er-dropdown>
      </div>

      <div class="right-header-container">
        <span class="header-right-user">
          {{
            getAccountManagerName.first_name
              ? getAccountManagerName.first_name +
                "\xa0\xa0" +
                getAccountManagerName.last_name
              : ""
          }}
        </span>

        <el-divider
          class="user-logout-divider"
          direction="vertical"
        ></el-divider>
        <er-button class="logout-button" @click="logout" type="text">
          <el-row type="flex" :gutter="5">
            <el-col>{{ $t("accountmanager.Comn_logout") }}</el-col>
            <el-col>
              <i class="el-icon-logout">
                <img class="logout-icon" src="@/assets/logout.svg" />
              </i>
            </el-col>
          </el-row>
        </er-button>
      </div>
    </div>
  </div>
</template>

<script>
import pondlogsLogo from "@/components/index/pondlogsLogo";
import erMenuItem from "@/components/base/erMenuItem.vue";
import { mapActions } from "vuex";

export default {
  components: {
    pondlogsLogo,
    erMenuItem
  },
  data: function() {
    return {
      langs: { Español: "es", English: "en", Chinese: "zh", Portuguese: "pt", Vietnamese: "vi" },
      revLangs: { es: "Español", en: "English", zh: "Chinese", pt: "Portuguese", vi: "Vietnamese" }
    };
  },
  computed: {
    getAccountManagerName() {
      return this.$store.getters["accountManager/getAccountManagerProfile"];
    },
    langSelected() {
      return this.revLangs[this.$i18n.locale];
    },
    activeRoute() {
      console.log(this.$route.path.split("/")[2]);
      return this.$route.path.split("/")[2];
    },
    showSubscription() {
      return this.getAccountManagerName.allow_subscription;
    },
    devicesList() {
      const deviceRouteObj = (index, label, route) => {
        return {
          index,
          label,
          route
        };
      };
      return [
        deviceRouteObj("gateways", "Gateway", "/account-manager/gateways"),
        deviceRouteObj(
          "pondmothers",
          "PondMother",
          "/account-manager/pondmothers"
        ),
        deviceRouteObj(
          "pondguards",
          "PondGuard",
          "/account-manager/pondguards"
        ),
        deviceRouteObj(
          "shrimptalks",
          "ShrimpTalk",
          "/account-manager/shrimptalks"
        )
      ];
    }
  },
  methods: {
    ...mapActions("accountManager", {
      accountManagerNotifyBackendOnLogout: "accountManagerNotifyBackendOnLogout"
    }),
    handleChangeInLanguage(value) {
      console.log(value);
      this.$i18n.locale = value;
      const indexPath = this.$route.meta.langKey;
      const appTitle = document.getElementById("appTitle");
      appTitle.innerHTML = `${this.$t(indexPath)} | ${this.$t("PondLogs")}`;
      localStorage.setItem(`lang`, value);
    },
    getSubMenuTitle(langKey) {
      const subMenuItems = [
        "Comn_gateway",
        "Comn_feed_blower",
        "Comn_pond_mother",
        "Comn_pond_guard",
        "Comn_shrimptalk",
        "shrimpsnap.device_name"
      ];
      if (subMenuItems.includes(langKey)) {
        return `(${this.$tc(langKey, 1)})`;
      }
      return "";
    },

    async logout() {
      await this.$store.dispatch("auth/accountManagerSignOut");
      try {
        await this.accountManagerNotifyBackendOnLogout();
      } catch (err) {
        console.log("error notifying on account manager logout");
      }
      this.$router.push("/");
    },
    async initComponent() {
      try {
        this.loading = true;
        await this.$store.dispatch("accountManager/fetchUserProfile");
      } catch (err) {
        console.log(err);
        // this.ehm__errorMessages(err, true);
      } finally {
        this.loading = false;
      }
    }
  },
  async mounted() {
    await this.initComponent();
  }
};
</script>

<style lang="scss">
.devices {
  @include responsiveProperty(
    font-size,
    $app_font_size_small,
    $app_font_size_1,
    $app_font_size_2
  );
}
.right-header-container {
  margin-top: -10px;
}
// .el-menu--horizontal > .er-menu__link > .er-menu-item {
//   height: 37px !important;
// }
.account-header {
  .el-submenu {
    margin-top: -6px;
    @media screen and (min-width: 1366px) {
      margin-top: -5px;
    }

    height: 40px !important;

    &__icon-arrow {
      margin-top: 2px !important;
    }
  }
  .el-submenu__title {
    color: #551a8b !important;
    line-height: 50px !important;
    // font-size: 16px;
    height: 46px !important;
  }
  .nav__header-title {
    @include responsiveProperty(
      font-size,
      $app_font_size_3,
      $app_font_size_xl,
      $app_font_size_xl
    );
    color: #1f2041;
    text-decoration: none;
    font-weight: 500;
  }
  .dealer-menu {
    // z-index: 1;
    height: 39px;
    @media screen and (min-width: 1366px) {
      height: 43px;
    }

    &.el-menu--horizontal > .el-menu-item {
      height: 37px !important;
      line-height: 37px !important;
      @media screen and (min-width: 1366px) {
        height: 41px !important;
        line-height: 41px !important;
      }
    }
    &.el-menu--horizontal > .er-menu__link > .er-menu-item {
      height: 37px !important;
      @media screen and (min-width: 1366px) {
        height: 41px !important;
      }
    }
  }
  .header-right-user {
    max-width: 200px;
    color: #606266;
    text-transform: capitalize;
    text-decoration: none;
    @include responsiveProperty(
      font-size,
      $app_font_size_1,
      $app_font_size_2,
      $app_font_size_3
    );
  }
  .er-dropdown {
    color: #606266;
  }
  .er-dropdown .er-dropdown-trigger__suffix .er-icon-caret {
    color: #606266;
  }

  .er-dropdown .er-dropdown-trigger__suffix {
    margin-left: 10px;
    margin-top: auto;
  }
}
</style>
