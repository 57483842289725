/**
Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021
**/
/**
File Name: timeslot.js
Description: This file contains the model, functions of timeslot object used all over the pondlogs customer site
*/
/* eslint-disable */
import PondMother from "./pondmother";
import {
  objectIdGenerator,
  timeStrHHmmVal,
  castSecsHHmmStr
} from "../utils/commonUtils";
import dateUtils from "@/utils/dateUtils";
import schedule from "@/constants/schedule";
class TimeSlot {
  constructor() {
    this._id = undefined;
    this.ui_id = objectIdGenerator();
    this.duration = 0;
    this.feed_percentage = 0;
    this.managed_by = "FARMER";
    this.ocf = 200;
    this.pond_mothers = [];
    this.shrimp_talk_id = "";
    this.status = 1;
    this.s_time_secs = schedule.MIN_S_TIME_SECS;
    this.s_time = castSecsHHmmStr(this.s_time_secs);
    this.e_time_secs = schedule.MAX_E_TIME_SECS_ALLOWED;
    this.e_time = castSecsHHmmStr(this.e_time_secs);
    this.timeRange = [
      dateUtils.formatDate(dateUtils.add(new Date(), { hours: 1 }), "HH:mm"),
      dateUtils.formatDate(dateUtils.add(new Date(), { hours: 2 }), "HH:mm")
    ];
    this.time_slot_id = this._id;
    this.total_feed = 0;
    this.dispensed_feed = 0;
    this.remaining_feed = 0;
    this.errorMessage = {
      feed_percentage: "",
      e_time: "",
      s_time: ""
    };
    this.feeding_level = -1;
    this.mode = "SCHEDULE";
  }
  get hasError() {
    return prop => {
      return this.errorMessage[prop] !== "";
    };
  }
  initTimeSlot(s_time, e_time) {
    this.s_time = s_time;
    this.e_time = e_time;
  }
  getSchedulePayloadFormat() {
    let timeSlotObj = {
      s_time: this.timeRange[0],
      e_time: this.timeRange[1],
      feed: this.total_feed,
      feed_percentage: this.feed_percentage,
      ocf: this.ocf,
      managed_by: this.managed_by,
      status: this.status,
      pond_mothers: this.pond_mothers.map(pm => {
        let pmPayloadObj = {
          pond_mother_code: pm.code,
          pond_mother_id: pm._id,
          ocf: pm.ocf,
          status: pm.status,
          feed: pm.total_feed,
          managed_by: pm.managed_by
        };
        return pmPayloadObj;
      })
    };
    return timeSlotObj;
  }

  isETimeGrtrSTime() {
    const ts = this;
    if (ts.e_time_secs > ts.s_time_secs) {
      return true;
    }
    return false;
  }
  isValidSTime() {
    const ts = this;
    if (ts.s_time_secs >= 900) {
      return true;
    }
    return false;
  }
  isValidETime() {
    const ts = this;
    if (ts.e_time_secs >= 86340) {
      return false;
    }
    return true;
  }
  cloneTimeSlotObject() {
    let ntimeSlot = new TimeSlot();
    let otimeSlot = this;
    Object.keys(otimeSlot).forEach(key => {
      if (key === "pond_mothers") {
        ntimeSlot[key] = otimeSlot[key].map(pm => {
          let newPM = new PondMother();
          newPM.castToPMObj(pm);
          return newPM;
        });
        return;
      } else if (key === "timeRange") {
        let dateRange = [];
        dateRange[0] = otimeSlot[key][0];
        dateRange[1] = otimeSlot[key][1];
        ntimeSlot[key] = dateRange;
        return;
      }
      ntimeSlot[key] = otimeSlot[key];
    });
    ntimeSlot.time_slot_id = otimeSlot._id;
    return ntimeSlot;
  }
  getFeedTemplatePayloadObj() {
    if (this.mode === "AUTOMATIC") {
      return {
        s_time: this.s_time,
        e_time: this.e_time,
        duration: this.duration,
        feed_percentage: this.feed_percentage,
        feeding_level: this.feeding_level,
        mode: this.mode
      };
    }
    return {
      s_time: this.s_time,
      e_time: this.e_time,
      duration: this.duration,
      feed_percentage: this.feed_percentage,
      mode: this.mode
    };
  }
  static getModeFromListTS(listTS) {
    return Array.from(new Set(listTS.map(x => x.mode)));
  }
  static getTimeSlotObjFromResponse(response) {
    const timeSlot = new TimeSlot();
    timeSlot._id = response._id;
    timeSlot.timeRange = [response.s_time, response.e_time];
    timeSlot.s_time = response.s_time;
    timeSlot.s_time_secs = timeStrHHmmVal(timeSlot.s_time);
    timeSlot.e_time = response.e_time;
    timeSlot.e_time_secs = timeStrHHmmVal(timeSlot.e_time);
    timeSlot.duration = response.duration;
    timeSlot.feed_percentage = response.feed_percentage;
    timeSlot.status = response.status;
    if (response.mode === "AUTOMATIC") {
      timeSlot.mode = response.mode;
      timeSlot.feeding_level = response.feeding_level;
    }
    // if (Object.keys(response).indexOf("managed_by") > -1) {
    //   if (response.managed_by === "FARMER") {
    //     timeSlot.managed_by = "FARMER";
    //     delete timeSlot.shrimp_talk_id;
    //   } else {
    //     timeSlot.managed_by = "SHRIMP_TALK";
    //     timeSlot.shrimp_talk_id = response.shrimp_talk_id;
    //   }
    //   timeSlot.managed_by = response.managed_by;
    //   timeSlot.modified = response.modified;
    //   timeSlot.ocf = response.ocf;
    //   timeSlot.remaining_feed = response.remaining_feed;

    //   timeSlot.dispensed_feed = response.dispensed_feed;
    //   timeSlot.timeRange = [response.s_time, response.e_time];
    //   timeSlot.total_feed = response.feed;
    //   timeSlot.pond_mothers = response.pond_mothers.map(res_pm => {
    //     const pmObj = new PondMother();
    //     pmObj.castToPMObj(res_pm);
    //     /**
    //      * while casting the pm object from response the _id field is assigned to pond_mother_id field
    //      * because _id is not the actual id of the pond_mother
    //      */
    //     pmObj._id = res_pm.pond_mother_id;
    //     pmObj.pond_mother_id = res_pm.pond_mother_id;
    //     pmObj.code = res_pm.pond_mother_code;
    //     pmObj.total_feed = res_pm.feed;
    //     pmObj.status = 1;
    //     return pmObj;
    //   });
    // }
    return timeSlot;
  }
}

export default TimeSlot;
