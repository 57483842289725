/**
Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021
**/
/**
File Name: shrimptalk.js
Description:  Contains all vuex store functions of ShrimpTalk
*/
import { $axios } from "../../config/axios";
import ShrimpTalk from "../../model/shrimptalk";
import { cloneDeep } from "lodash";
import bkPermissions from "@/constants/bkPermissions";
import ShrimpTalkService from "../../services/ShrimpTalkService";
import i18n from "@/config/i18n";
const state = {
  shrimpTalks: [],
  mapStidSt: new Map(),
  mapPondidPond: new Map(),
  mapPmidPm: new Map()
};
const getters = {
  getShrimpTalks: state => {
    return state.shrimpTalks.map(shrimp => {
      const st = cloneDeep(shrimp);
      const feedlevel = st.settings.feeding_level;
      const obj = {
        0: "Comn_normal",
        1: "Comn_economical",
        2: "Comn_aggressive"
      };
      // st.feeding_level = i18n.t(obj[feedlevel]);
      st.feeding_level = i18n.availableLocales.map(locale => {
        return i18n.tc(obj[feedlevel], 1, locale);
      });
      return st;
    });
  },
  getObjStIdSt: state => {
    const arrSts = state.shrimpTalks;
    const objStIdSt = {};
    arrSts.forEach(st => {
      objStIdSt[st._id] = st;
    });
    return objStIdSt;
  },
  getMapStidSt: state => state.mapStidSt,
  getStMapPondidPond: state => state.mapPondidPond,
  getStMapPmidPm: state => state.mapPmidPm
};
const mutations = {
  SET_SHRIMP_TALK_DATA: function(state, shrimpTalkData) {
    if (shrimpTalkData != null) {
      const stArr = [];
      shrimpTalkData.forEach(shrimptalk => {
        const stObj = new ShrimpTalk();
        ["start_time", "end_time", "pause_start", "pause_end"].reduce(
          (accST, settingKey) => {
            if (
              accST.settings &&
              accST.settings[settingKey] &&
              accST.settings[settingKey].length > 5
            ) {
              accST.settings[settingKey] = accST.settings[settingKey].substring(
                accST.settings[settingKey],
                accST.settings[settingKey].length - 3
              );
            }
            return accST;
          },
          shrimptalk
        );
        stObj.castToSTObj(shrimptalk);
        stArr.push(stObj);
      });
      state.shrimpTalks = stArr;
    } else {
      state.shrimpTalks = null;
    }
  },
  SET_MAP_ST_PONDID_POND(state, ponds) {
    const mapPondidPond = new Map();
    ponds.forEach(pond => {
      mapPondidPond.set(pond._id, pond);
    });
    state.mapPondidPond = mapPondidPond;
  },
  SET_MAP_ST_PMID_PM(state, pondMothers) {
    const mapPmidPm = new Map();
    pondMothers.forEach(pm => {
      mapPmidPm.set(pm._id, pm);
    });
    state.mapPmidPm = mapPmidPm;
  },
  SET_MAP_STID_ST(state) {
    const mapStidSt = new Map();
    state.shrimpTalks.forEach(st => {
      mapStidSt.set(st.shrimp_talk_id, st);
    });
    state.mapStidSt = mapStidSt;
  }
};
const actions = {
  fetchAllShrimpTalks: function(context, queryParams = {}) {
    return new Promise((resolve, reject) => {
      $axios
        .get("/ponds/shrimp-talks", {
          params: queryParams
        })
        .then(response => {
          context.commit("SET_SHRIMP_TALK_DATA", response.data.shrimp_talks);
          context.commit("SET_MAP_STID_ST");
          resolve({
            message: response.data.message,
            totalSTs: response.data.total
          });
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchShrimpTalksList: async function(context, queryParams = {}) {
    const response = await ShrimpTalkService.fetchShrimpTalksList(queryParams);
    const shrimpTalksData = response.data;
    context.commit("SET_SHRIMP_TALK_DATA", shrimpTalksData.shrimp_talks);
    context.commit("SET_MAP_STID_ST");
    context.commit("SET_MAP_ST_PONDID_POND", shrimpTalksData.ponds);
    context.commit("SET_MAP_ST_PMID_PM", shrimpTalksData.pond_mothers);
    return shrimpTalksData;
  },

  fetchAShrimpTalk: function(context, shrimpTalkId) {
    return new Promise((resolve, reject) => {
      $axios
        .get("/ponds/shrimp-talks/" + shrimpTalkId)
        .then(response => {
          resolve(response.data.shrimp_talk);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchShrimpTalksOnPondId: async function(context, { pondId, params }) {
    const requiredPermissions = bkPermissions["/ponds/:pond_id/pond-mothers"];
    const canAccessApi = context.rootGetters[
      "user/canUserAccessApiByBkPermissions"
    ](requiredPermissions);
    if (!canAccessApi) {
      console.error("No Permissions to call this api");
      return [];
    }
    const response = await ShrimpTalkService.fetchStDetailsByPondId(
      pondId,
      params
    );
    return response.data.shrimp_talks;
  },
  addShrimpTalkDetails: function(context, shrimpTalks) {
    return new Promise((resolve, reject) => {
      $axios
        .post("/ponds/shrimp-talks", shrimpTalks)
        .then(response => {
          resolve(response.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  updateShrimpTalkDetails: function(context, shrimpTalks) {
    return new Promise((resolve, reject) => {
      $axios
        .patch("/ponds/shrimp-talks", shrimpTalks)
        .then(response => {
          resolve(response.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  updateShrimpTalkSettingDetails: function(context, shrimpTalks) {
    return new Promise((resolve, reject) => {
      $axios
        .patch("/ponds/shrimp-talks/settings", shrimpTalks)
        .then(response => {
          resolve(response.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  unAssignShrimpTalks: function(context, shrimpTalks) {
    return new Promise((resolve, reject) => {
      $axios
        .delete(`/ponds/shrimp-talks/${shrimpTalks}`)
        .then(response => {
          // context.commit('SET_POND_MOTHER_DATA',response.data.pondMothers)
          resolve(response.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  addPondMotherToShrimpTalk: async function(context, { query: stId, params }) {
    const response = await ShrimpTalkService.addPondMotherToShrimpTalk(
      stId,
      params
    );
    return response.data;
  },
  removePondMotherFromShrimpTalk: async function(
    context,
    { query: stId, params }
  ) {
    const response = await ShrimpTalkService.removePondMotherFromShrimpTalk(
      stId,
      params
    );
    return response.data;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
